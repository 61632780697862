import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "baked-in-static-data"
    }}>{`Baked-in static data`}</h1>
    <p>{`You saw lots of loading spinners in the previous exercise. But the list of SpaceX launches doesn't change that often.`}</p>
    <p>{`Time to look into JAMStack's biggest selling point 👉 data baked into the initial HTML.`}</p>
    <h2 {...{
      "id": "gatsbys-usestaticquery"
    }}>{`Gatsby's useStaticQuery`}</h2>
    <p>{`Gatsby uses GraphQL throughout its stack. Everything is data and all data is GraphQL. It comes with a built-in `}<inlineCode parentName="p">{`useStaticQuery`}</inlineCode>{` hook that lets you run GraphQL queries during build-time.`}</p>
    <h3 {...{
      "id": "exercise"
    }}>{`Exercise`}</h3>
    <p>{`Continue with your Gatsby project from before, or jump into mine in `}<inlineCode parentName="p">{`exercise-5`}</inlineCode>{`. Make a copy of your `}<inlineCode parentName="p">{`<LaunchList>`}</inlineCode>{` component using a static query.`}</p>
    <p>{`Install `}<inlineCode parentName="p">{`gatsby-source-graphql`}</inlineCode>{` and configure it in `}<inlineCode parentName="p">{`gatsby-config.js`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// gatsby-config.js

{
    resolve: 'gatsby-source-graphql',
    options: {
        typeName: 'SPACEX',
        fieldName: 'spacex',
        url: 'https://api.spacex.land/graphql'
    }
}
`}</code></pre>
    <p>{`You can now query the SpaceX API from anywhere in Gatsby. Use it to create new pages based on data in `}<inlineCode parentName="p">{`gatsby-node.js`}</inlineCode>{`, or as a static query in any React component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { graphql, useStaticQuery } from "gatsby"

export const LaunchList = ({ limit = 10 }) => {
  const data = useStaticQuery(graphql\`
    query LaunchesPast {
      spacex {
        launchesPast(limit: 10) {
          mission_name
          links {
            article_link
            video_link
          }
          rocket {
            rocket_name
          }
        }
      }
    }
  \`)

  // list launches, they're in data.spacex.launchesPast
}
`}</code></pre>
    <p>{`Note that you have to wrap your query in the `}<inlineCode parentName="p">{`spacex`}</inlineCode>{` namespace, as defined in `}<inlineCode parentName="p">{`gatsby-source-graphql`}</inlineCode>{` config. And you can't pass-in variables.`}</p>
    <h3 {...{
      "id": "solution"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-5-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "nextjss-getserversideprops"
    }}>{`NextJS's getServerSideProps`}</h2>
    <p>{`NextJS gives you 2 options to bake data into your HTML. On-demand with `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` and statically with `}<inlineCode parentName="p">{`getStaticProps`}</inlineCode>{`.`}</p>
    <p>{`You can use GraphQL or REST to populate those values, we'll go with the React Query approach. The integration is fantastic.`}</p>
    <h3 {...{
      "id": "exercise-1"
    }}>{`Exercise`}</h3>
    <p>{`Take your NextJS project from exercise-3, or jump into mine at `}<inlineCode parentName="p">{`exercise-5`}</inlineCode>{`. Use the `}<a parentName="p" {...{
        "href": "https://api.spacex.land/rest/"
      }}>{`SpaceX REST API`}</a>{` to create a page that lists SpaceX's last 10 launches.`}</p>
    <p>{`Copy the REST API fetch function from your Gatsby project. Use it to populate the `}<inlineCode parentName="p">{`launches`}</inlineCode>{` prop of a new page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/launches.js

async function getLaunches(limit) {
  const res = await fetch(
    \`https://api.spacex.land/rest/launches-past?limit=\${limit}\`
  )
  return res.json()
}

export async function getServerSideProps() {
  const launches = await getLaunches(10)

  return {
    props: {
      launches,
    },
  }
}

const Launches = ({ launches }) => {
  // render here
}
`}</code></pre>
    <p>{`Look at your network log. Notice there's no API request, yet the data shows up 🤘`}</p>
    <p>{`NextJS makes the request for you as it generates the page.`}</p>
    <h3 {...{
      "id": "solution-1"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-5-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "nextjss-getstaticprops"
    }}>{`NextJS's getStaticProps`}</h2>
    <p>{`Fetching SpaceX launch data on every page load seems wasteful. It doesn't change that often.`}</p>
    <p>{`NextJS lets you fetch data at build-time using `}<inlineCode parentName="p">{`getStaticProps`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "exercise-2"
    }}>{`Exercise`}</h3>
    <p>{`Change `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{` to `}<inlineCode parentName="p">{`getStaticProps`}</inlineCode>{`. That's it :)`}</p>
    <p>{`Notice how much faster the page loads now.`}</p>
    <h3 {...{
      "id": "solution-2"
    }}>{`Solution`}</h3>
    <p>{`Find mine in the `}<inlineCode parentName="p">{`exercise-5-solution`}</inlineCode>{` branch.`}</p>
    <h2 {...{
      "id": "bonus-exercise"
    }}>{`Bonus exercise`}</h2>
    <p>{`Try to create a page for each SpaceX rocket with Gatsby and NextJS`}</p>
    <h3 {...{
      "id": "gatsby"
    }}>{`Gatsby`}</h3>
    <p>{`You'll have to create a new Rocket template and create pages in `}<inlineCode parentName="p">{`gatsby-node`}</inlineCode>{` like we did for dynamic routing. Use graphql to get a list of rockets and call `}<inlineCode parentName="p">{`createPage`}</inlineCode>{` for each.`}</p>
    <p>{`Use a static query to list all the rockets with links to individual pages.`}</p>
    <p>{`This is the query to fetch all the rockets with some info:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-graphql"
      }}>{`{
  rockets {
    active
    boosters
    company
    cost_per_launch
    engines {
      engine_loss_max
      layout
      number
    }
    country
    description
    height {
      meters
    }
    first_flight
    id
    name
  }
}
`}</code></pre>
    <h3 {...{
      "id": "nextjs"
    }}>{`NextJS`}</h3>
    <p>{`Use dynamic routing to define a new `}<inlineCode parentName="p">{`[rocket]`}</inlineCode>{` page. Then fetch its data with `}<inlineCode parentName="p">{`getStaticProps`}</inlineCode>{`. You'll need to define all possible pages with `}<inlineCode parentName="p">{`getStaticPaths`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// pages/[rocket].js

export async function getStaticPaths() {
  // get list of rockets

  // create paths
  const paths = rockets.map((rocket) => ({
    params: { rocket: rocket.id },
  }))

  return {
    paths,
    // 404 when hitting an unknown page
    fallback: false,
  }
}
`}</code></pre>
    <p>{`If you fetch a page's data with `}<inlineCode parentName="p">{`getServerSideProps`}</inlineCode>{`, you don't need to pre-define paths.`}</p>
    <p>{`The REST API to list rockets is `}<inlineCode parentName="p">{`https://api.spacex.land/rest/rockets`}</inlineCode>{`, for individual rockets, use `}<inlineCode parentName="p">{`https://api.spacex.land/rest/rocket/{id}`}</inlineCode>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      